export enum DeadlineType {
  Fixed = 'fixed-date',
  Evergreen = 'evergreen',
  OptinToOffer = 'optin-to-offer',
  Tripwire = 'tripwire',
}

export enum DeadlineUnit {
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
  Seconds = 'seconds',
}

export enum AfterDeadlineAction {
  RedirectToOffer = 'redirect_to_offer',
  RedirectToUrl = 'external_url',
  RemoveBonuses = 'remove_bonuses',
}

export enum CurrencyCode {
  AUD = 'A',
  CAD = 'C',
  NZD = 'NZ',
  USD = 'USD',
}
